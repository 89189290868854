<script setup>
    import { provide } from 'vue';

    import { TweakPane } from '@resn/gozer-vue';

    import GlobalProviders from '~/providers/GlobalProviders.vue';

    provide('sandbox', true);
</script>

<template>
    <GlobalProviders>
        <TweakPane>
            <div class="layout-sandbox">
                <slot />
            </div>
        </TweakPane>
    </GlobalProviders>
</template>
